import * as Sentry from '@sentry/react';
import { Zodios, ZodiosPlugin } from '@zodios/core';
import { ZodiosHooks } from '@zodios/react';
import axios from 'axios';
import { format } from 'date-fns/format';
import { z } from 'zod';

import { env } from '~/env';

import { cartConfirmResponse, cartResponse } from './endpoints/cart';
import { cartLineResponse } from './endpoints/cartLine';
import { cartLineItemResponse } from './endpoints/cartLineItem';
import { contactsResponse } from './endpoints/contact';
import { dashboardResponse } from './endpoints/dashboard';
import { filterGroupResponse } from './endpoints/filterGroup';
import { openItemActionResponse, openItemResponse } from './endpoints/openItem';
import { openItemsResponse } from './endpoints/openItems';
import { quickItemsResponse } from './endpoints/quickItems';
import { stylingResponse } from './endpoints/styling';
import { cartActionType, openItemActionType } from './schemas/action.schema';
import { cartLineItemType, cartLineSchema } from './schemas/item.schema';
import { messageSchema } from './schemas/message';
import { dateFormat, dateRegex } from './schemas/utils.schema';

const createResponseSchema = <T extends z.ZodTypeAny>(schema: T) =>
  z
    .object({
      data: schema,
      mem_usage: z.string(),
      rows: z.number().int().min(0),
      success: z.literal(true),
      time_spend: z.number().positive(),
    })
    .transform((data) => data.data as z.infer<T>);

export const minSearchstringLength = 3;

/** App wide Axios instance for all requests, Authentication headers will be set on this instance. */
export const axiosInstance = axios.create();
export const apiClient = new Zodios(
  env.VITE_API_URL,
  [
    {
      method: 'get',
      path: '/webshop/dashboard',
      alias: 'dashboard',
      description: 'Get the `Dashboard` contents (incl. `Contact` details, `News Items` and `Filter Groups.',
      response: createResponseSchema(dashboardResponse),
    },
    {
      method: 'get',
      path: '/webshop/cart',
      alias: 'cart',
      description: 'Get the active `Cart` contents,',
      response: createResponseSchema(cartResponse),
    },
    {
      method: 'post',
      path: '/webshop/cart/lines',
      alias: 'addCartLine',
      description: 'Add a `CartLine` to the `Cart`. Returns the cart line details',
      requestFormat: 'json',
      parameters: [
        {
          name: 'body',
          type: 'Body',
          schema: cartLineSchema,
        },
      ],
      response: createResponseSchema(cartLineResponse),
    },
    {
      method: 'get',
      path: '/webshop/cart/lines/:type/:key',
      alias: 'cartLineItem',
      description: 'Get a single `CartLineItem` by `type` and `key`.',
      parameters: [
        {
          name: 'type',
          type: 'Path',
          schema: cartLineItemType,
        },
        {
          name: 'key',
          type: 'Path',
          schema: z.string(),
        },
      ],
      response: createResponseSchema(cartLineItemResponse),
    },
    {
      method: 'put',
      path: '/webshop/cart/lines/:type/:key',
      alias: 'updateCartLineItem',
      description: 'Update a `CartLineItem` by `type` and `key`.',
      requestFormat: 'json',
      parameters: [
        {
          name: 'body',
          type: 'Body',
          schema: cartLineSchema,
        },
      ],
      response: createResponseSchema(cartLineItemResponse),
    },
    {
      method: 'put',
      path: '/webshop/cart',
      alias: 'confirmCart',
      description: 'Confirm a `Cart`.',
      requestFormat: 'json',
      parameters: [
        {
          name: 'body',
          type: 'Body',
          schema: z.object({
            actionType: cartActionType,
          }),
        },
      ],
      response: createResponseSchema(cartConfirmResponse),
    },

    {
      method: 'delete',
      path: '/webshop/cart/lines/:type/:key',
      alias: 'deleteCartLineItem',
      description: 'Delete a single `CartLineItem` by `type` and `key`.',
      response: createResponseSchema(
        z.object({
          messages: z.array(messageSchema).nullish(),
        }),
      ),
    },
    {
      method: 'get',
      path: '/webshop/open-items',
      alias: 'openItems',
      description: 'Get the `OpenItems`.',
      response: createResponseSchema(openItemsResponse),
    },
    {
      method: 'get',
      path: '/webshop/open-items/:type/:key',
      alias: 'openItem',
      description: 'Get a single `OpenItem` by `type` and `key`.',
      response: createResponseSchema(openItemResponse),
    },
    {
      method: 'put',
      path: '/webshop/open-items/:type/:key',
      alias: 'updateOpenItem',
      description: 'Update an `Item` by `type` and `key`.',
      requestFormat: 'json',
      parameters: [
        {
          name: 'body',
          type: 'Body',
          schema: cartLineSchema, // ! add own schema
        },
      ],
      response: createResponseSchema(openItemResponse),
    },
    {
      method: 'put',
      path: '/webshop/open-items/:type/:key_action',
      alias: 'actionOpenItem',
      description: 'Perform an action on a single `OpenItem` by `type` and `key`.',
      requestFormat: 'json',
      parameters: [
        {
          name: 'body',
          type: 'Body',
          schema: z.object({
            actionType: openItemActionType,
          }),
        },
      ],
      response: createResponseSchema(openItemActionResponse),
    },
    {
      method: 'get',
      path: '/webshop/quick-items',
      alias: 'quickItems',
      description: 'Get the `QuickItems`.',
      response: createResponseSchema(quickItemsResponse),
    },
    {
      method: 'get',
      path: '/webshop/filter-groups/:id',
      alias: 'filterGroup',
      description: 'Get a single `FilterGroup` by `id`.',
      response: createResponseSchema(filterGroupResponse),
      parameters: [
        {
          name: 'mode',
          description: 'mode of the response',
          type: 'Query',
          schema: z.enum(['list', 'quick', 'schematic']).default('list'),
        },
        {
          name: 'playercount',
          description: 'number of players',
          type: 'Query',
          schema: z.string().optional(),
        },
        {
          name: 'date',
          description: 'date in `yyyy-MM-dd` format',
          type: 'Query',
          schema: z.string().regex(dateRegex).default(format(new Date(), dateFormat)),
        },
      ],
    },
    {
      method: 'get',
      path: '/webshop/buddies/:itemtype/:itemkey',
      alias: 'buddies',
      description:
        'Get the latest `contact`s with which the signed in `User` was in a `Reservation`. Returns at most 10 contacts.',
      response: createResponseSchema(contactsResponse),
    },
    {
      method: 'get',
      path: '/webshop/players/:itemtype/:itemkey',
      alias: 'searchPlayers',
      description: 'Search for `Players`.',
      response: createResponseSchema(contactsResponse),
      parameters: [
        {
          name: 'searchstring',
          description: 'search string',
          type: 'Query',
          schema: z.string().min(minSearchstringLength),
        },
      ],
    },
    {
      method: 'get',
      path: '/webshop/styling',
      alias: 'styling',
      description: 'Get the `Styling`.',
      response: createResponseSchema(stylingResponse),
    },
    {
      method: 'get',
      path: '/webshop/translations/:lng',
      alias: 'translations',
      description: 'Get the translations (i18n) for this user.',
      response: createResponseSchema(z.record(z.string())),
    },
  ],
  {
    axiosInstance,
  },
);

/** Custom error to more easily identify these kinds of errors in Sentry */
class ApiError extends Error {
  __proto__ = Error;

  constructor(message: string) {
    super(message);
    Object.setPrototypeOf(this, ApiError.prototype);
  }
}

/**
 * Plugin to intercept responses, validate them (even when response validation is turned off in prod) and
 * log invalid responses to Sentry.
 */
export const sentryPlugin: ZodiosPlugin = {
  name: 'sentry',
  response: async (api, config, res) => {
    const test = api?.find((q) => q.method === config.method && q.path === config.url)?.response.safeParse(res.data);

    if (test?.success === false) {
      const issuesArr = test.error.issues.map(({ path, ...rest }) => ({ ...rest, path: path.join('.') }));
      const issuesStr = issuesArr.map(({ code, ...rest }) => `${code}:${JSON.stringify(rest)}`).join('\n');

      Sentry.captureException(new ApiError(`${config.method} ${config.url}\n${issuesStr}`), {
        extra: {
          method: config.method,
          baseURL: config.baseURL,
          url: config.url,
          params: JSON.stringify(config.params),
          issues: issuesArr,
          response: res.data,
        },
      });

      await Sentry.flush();
    }
    return res; // Return the response so it can be used in the next plugin.
  },
};

apiClient.use(sentryPlugin);

export const apiHooks = new ZodiosHooks('webshopApi', apiClient);
