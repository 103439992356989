/* eslint-disable @typescript-eslint/no-magic-numbers */
import * as Sentry from '@sentry/react';
import React from 'react';
import {
  createBrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';

import { env } from './env';
import { Auth } from './hooks/useAuth/AuthContext';

export const setSentryUser = (user?: Required<NonNullable<Auth>>['user']) => {
  if (!user) {
    Sentry.setUser(null);
    Sentry.setContext('userDetails', null);
  } else {
    Sentry.setUser({ id: user.cartContactType === 'external' ? 'guest' : user.contactId, segment: user.section });
    Sentry.setContext('userDetails', {
      section: user.section,
      language: user.languageCode,
      checkedIn: user.cartContactType === 'external' ? false : user.checkedIn,
    });
  }
};

Sentry.init({
  dsn: env.VITE_SENTRY_DSN,
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration(),
  ],
  environment: env.VITE_APP_ENV,
  release: env.VITE_APP_VERSION,
  sampleRate: env.VITE_APP_ENV === 'prod' ? 0.05 : 1.0,
  tracesSampleRate: env.VITE_APP_ENV === 'prod' ? 0.01 : 1.0,
  replaysSessionSampleRate: env.VITE_APP_ENV === 'prod' ? 0 : 1.0,
  replaysOnErrorSampleRate: 1.0,
  normalizeDepth: 5,
});

export const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(
  createBrowserRouter,
) as typeof createBrowserRouter;
