import './globalStyles/fonts.css';
import './i18n';

import { CssBaseline, GlobalStyles, ThemeProvider } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { nl } from 'date-fns/locale';
import { SnackbarProvider } from 'notistack';
import { FC, useMemo } from 'react';
import { RouterProvider } from 'react-router-dom';

import { apiHooks } from './api/api';
import { ssoClient } from './api/sso';
import PageLoader from './components/PageLoader';
import Snackbar from './components/ui/molecules/Snackbar/Snackbar';
import createAppTheme from './globalStyles/appTheme';
import { useAuth } from './hooks/useAuth';
import { globalAuth } from './hooks/useAuth/AuthContext';
import { longStaleTime } from './main';
import ErrorBoundary from './pages/ErrorBoundary';
import MainLayout from './pages/Layouts/MainLayout';
import AppRoutes from './pages/routes';
import { sentryCreateBrowserRouter } from './sentryCreateBrowserRouter';

const router = sentryCreateBrowserRouter([
  {
    element: <MainLayout />,
    children: [
      {
        lazy: () => import('./pages/Layouts/ProtectedLayout'),
        children: [
          // PROTECTED ROUTES
          {
            path: AppRoutes.Checkout,
            lazy: () => import('./pages/Checkout/Checkout'),
          },
          {
            lazy: () => import('./pages/ClubParent/ClubParent'),
            children: [
              {
                path: AppRoutes.ClubList,
                lazy: () => import('./pages/ClubList'),
              },
              {
                path: AppRoutes.ClubSchematic,
                lazy: () => import('./pages/ClubSchematic'),
              },
            ],
          },
          {
            path: AppRoutes.Dashboard,
            lazy: () => import('./pages/Dashboard'),
          },
          {
            path: AppRoutes.ReservationDetails,
            lazy: () => import('./pages/ReservationDetails'),
          },
        ],
      },
      {
        lazy: () => import('./pages/Layouts/PublicLayout'),
        children: [
          // PUBLIC ROUTES WITH REDIRECT
          {
            path: AppRoutes.Unauthorized,
            lazy: () => import('./pages/Unauthorized'),
          },
          // END OF PUBLIC ROUTES WITH REDIRECT
        ],
      },
      // PUBLIC ROUTES WITHOUT REDIRECT
      {
        path: AppRoutes.SsoSignIn,
        lazy: () => import('./pages/Sso'),
        loader: async (url) => {
          if (!url.params.token) return null;
          globalAuth.value = null;

          try {
            const response = await ssoClient.sso({ queries: { ssostring: url.params.token } });
            return [response, null];
          } catch (error) {
            return [null, error];
          }
        },
      },
      {
        path: '*',
        lazy: () => import('./pages/NotFound'),
      },
      // END OF PUBLIC ROUTES WITHOUT REDIRECT
    ],
  },
]);

const App: FC = () => {
  const { auth } = useAuth();

  const { data: stylingData } = apiHooks.useStyling(undefined, {
    enabled: !!auth && auth.accessToken !== undefined && auth.webshopKey !== undefined,
    staleTime: longStaleTime,
  });

  const theme = useMemo(() => {
    if (!auth?.user) return createAppTheme();
    return createAppTheme(stylingData);
  }, [auth?.user, stylingData]);

  return (
    <LocalizationProvider adapterLocale={nl} dateAdapter={AdapterDateFns}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <GlobalStyles
          styles={{
            html: { height: '100%' },
            body: { height: '100%', background: theme.palette.background.default },
            '#root': { height: '100%', display: 'flex', flexDirection: 'column', justifyContent: { sm: 'center' } },
          }}
        />
        <SnackbarProvider
          Components={{
            success: Snackbar,
            error: Snackbar,
            warning: Snackbar,
            info: Snackbar,
            closable: Snackbar,
          }}
          anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
          preventDuplicate
        >
          <ErrorBoundary>
            <RouterProvider fallbackElement={<PageLoader />} router={router} />
          </ErrorBoundary>
        </SnackbarProvider>
      </ThemeProvider>
    </LocalizationProvider>
  );
};

export default App;
